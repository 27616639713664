import { createWebHistory, createRouter } from "vue-router";
import axios from 'axios';
import routes from './routes';
import appConfig from "../../app.config";

function findCommonElement(array1, array2) {
         
    // Loop for array1
    for(let i = 0; i < array1.length; i++) {
         
        // Loop for array2
        for(let j = 0; j < array2.length; j++) {
             
            // Compare the element of each and
            // every element from both of the
            // arrays
            if(array1[i] === array2[j]) {
             
                // Return if common element found
                return true;
            }
        }
    }
     
    // Return if no common element exist
    return false;
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

// API based Authentication
// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {


  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const requiredRoles = routeTo.meta.appRoles || ['ROLE_ADMIN'];

//   next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });

//   if (authRequired) return next(); // TODO: Weer uitzetten als authenticatie werkt
  if (!authRequired) return next();

  axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt'); // for all requests
  // await axios.get('http://localhost:8082/json/auth/profile.json').then((data) => {
  await axios.get('/api/profile').then((data) => {
    localStorage.setItem('userdata', JSON.stringify(data.data.user));
    localStorage.setItem('userid', data.data.user._id);
    localStorage.setItem('user', JSON.stringify(data.data.user));

    let user = JSON.parse(localStorage.getItem('user'));
    let userRoles = user ? user.app_roles : [];

    if (!findCommonElement(userRoles, requiredRoles) && authRequired) {
        next('/not-allowed');
    } else {
        next();
    }
  }).catch(() => {
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
  });
});

router.beforeEach((routeTo, routeFrom, next) => {
    // const publicPages = ['/login', '/register', '/forgot-password', '/not-allowed'];
    // console.log(routeTo.meta.authRequired); 
    // const authpage = !publicPages.includes(routeTo.path);
    const authpage = routeTo.meta.authRequired;
    const loggeduser = localStorage.getItem('user');


    if (authpage && !loggeduser) {
        // return next(); // TODO: TERUGZETTEN
      return next('/login');
    }

    next();
});



router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }
  document.title = routeTo.meta.title + ' | ' + appConfig.title;
  // If we reach this point, continue resolving the route.
  next();
});

export default router;

<template>
 <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
    html {
        scroll-padding-top: 180px;
    }
</style>